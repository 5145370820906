'use client';

import { useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { getProductPublicWebPopularProductList } from '@/lib/services/zhuye';
import { Goods, NewLoading } from '@/components/commons';
import { useRouter } from 'next/router';
import { useDarkModeProvider } from '@/lib/context';

const PopularForH5 = ({
  noMoreButton = false,
  handleCollection,
  collectData,
  selectedGoods,
}: {
  noMoreButton?: boolean;
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
  collectData?: any[];
  selectedGoods?: any;
}) => {
  const { pathname } = useRouter();
  const t = useTranslations('Home');
  const pageSize = 6;
  const [pageNo, setPageNo] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [renderData, setRenderData] = useState<any[]>([]);
  // 热门商品列表
  const { loading } = useRequest(
    () =>
      getProductPublicWebPopularProductList({
        pageNo: pageNo,
        pageSize: pageSize,
      }),
    {
      manual: !(pageSize > 0),
      cacheKey: 'home-popular-h5',
      refreshDeps: [pageNo, pageSize],
      onSuccess: (response) => {
        const oldData = [...renderData];
        const newData = oldData.concat(response?.data?.list || []);

        setRenderData(newData);
        setHasNextPage(response?.data?.hasNextPage || false);
      },
    },
  );

  const { darkMode } = useDarkModeProvider();

  useEffect(() => {
    if (selectedGoods) {
      setRenderData((renderData || []).map((x) => (x.productId === selectedGoods.productId ? selectedGoods : x)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoods]);

  return (
    <section
      className={`box-border w-full ${darkMode ? 'bg-black' : 'bg-[#f5f6f6]'} ${renderData && !!renderData.length ? 'block' : 'hidden'} ${noMoreButton ? 'py-0' : 'py-3'}`}
    >
      <div className="w-full">
        {noMoreButton ? (
          ''
        ) : (
          <h2 className={`mb-3 text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>{t('popular')}</h2>
        )}
        <div className={`noScrollbar flex w-full flex-row items-start justify-between overflow-x-auto`}>
          {(renderData || [])
            .map((goodsItem) => {
              return {
                ...goodsItem,
                favorited:
                  pathname === '/favorites'
                    ? !!(collectData || []).find((x) => x.productId === goodsItem.productId)
                    : goodsItem.favorited,
              };
            })
            .map((x) => {
              return (
                <div key={x.productId} className="mr-2 w-36 last:mr-0">
                  <Goods
                    item={{
                      ...x,
                      favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                    }}
                    width={144}
                    noAnimation
                    handleCollection={(value, goods) => {
                      if (handleCollection) {
                        handleCollection(value, goods);
                      }

                      const newData = renderData ? [...renderData] : [];
                      if (value) {
                        const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                        if (index > -1) {
                          newData[index].favorited = true;
                        }
                      } else {
                        const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                        if (index > -1) {
                          newData[index].favorited = false;
                        }
                      }
                      setRenderData(newData);
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
      {noMoreButton ? (
        ''
      ) : (
        <div className="mt-3 flex h-12 w-full flex-row items-center justify-center">
          {loading ? (
            <NewLoading />
          ) : hasNextPage ? (
            <div
              onClick={() => {
                if (loading) {
                  return;
                }
                setPageNo(pageNo + 1);
              }}
              className={`flex h-12 w-full flex-row items-center justify-center rounded-xl text-base font-medium ${darkMode ? 'bg-[#3a969b3d] text-[#3A969B]' : 'bg-[#4dbac03d] text-primary'}`}
            >
              {t('showMore')}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </section>
  );
};

export default PopularForH5;
