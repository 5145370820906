'use client';

import { useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { getProductWebBrowseHistory } from '@/lib/services/zhuye';
import { Goods, NewLoading } from '@/components/commons';
import { useAuthProvider, useDarkModeProvider } from '@/lib/context';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { useRouter } from 'next/router';

/**
 * 最近浏览商品
 * @returns
 */
const RecentlyViewed = ({
  inUserCenter = false,
  inCartPage = false,
  inSearchPage = false,
  selectedGoods,
  handleCollection,
  collectData,
}: {
  inUserCenter?: boolean;
  inCartPage?: boolean;
  inSearchPage?: boolean;
  selectedGoods?: any;
  collectData?: any[];
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
}) => {
  const { isLogin } = useAuthProvider();

  const { pathname } = useRouter();
  const [isLoginNew, setIsLoginNew] = useState<boolean>(false);
  const [renderData, setRenderData] = useState<any[]>([]);

  useEffect(() => {
    setIsLoginNew(!!isLogin);
  }, [isLogin]);

  const t = useTranslations('Home');
  const { darkMode } = useDarkModeProvider();

  // 每行显示的个数
  const [rowQuantity, setRowQuantity] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [paddingL, setPaddingL] = useState<number>(0);

  useEffect(() => {
    setWidth(document.documentElement.clientWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWidth(document.documentElement.clientWidth);
  };

  // 120px是左右边距，固定布局
  useEffect(() => {
    setRenderData([]);
    const containerWidth = inUserCenter ? width - 120 - 120 - 300 - 24 - 24 - 24 : width - 120 - 120;
    const newRowQuantity =
      Math.floor((inUserCenter ? width - 120 - 120 - 300 - 24 - 24 - 24 : width - 120 - 120) / 190) -
      (containerWidth % 190 === 0 ? 1 : 0);
    setRowQuantity(newRowQuantity);
    setPaddingL(Number(((width - 120 - 120 - 180 * newRowQuantity) / (newRowQuantity - 1)).toFixed(2)));
  }, [inUserCenter, width]);

  // 最近浏览商品列表 -- 需要登录
  const { loading, run } = useRequest(
    () =>
      getProductWebBrowseHistory({
        pageNo: 1,
        pageSize: inUserCenter ? rowQuantity * 2 : inSearchPage || inCartPage ? rowQuantity : 30,
      }),
    {
      manual: true,
      cacheKey: 'recent-browse-history',
      onSuccess: (response) => {
        setRenderData(response?.data?.list || []);
      },
    },
  );

  useEffect(() => {
    if (isLoginNew && rowQuantity > 0) {
      run();
    }
  }, [isLoginNew, rowQuantity, run]);

  useEffect(() => {
    if (selectedGoods) {
      setRenderData((renderData || []).map((x) => (x.productId === selectedGoods.productId ? selectedGoods : x)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoods]);

  const RenderData = () => (
    <section
      className={`box-border w-full py-6 ${darkMode ? (inUserCenter ? 'bg-[#141414]' : 'bg-black') : inUserCenter ? 'bg-white' : 'bg-[#f5f6f6]'}`}
    >
      <div className={inUserCenter ? '' : 'container'}>
        <h2 className={`mb-6 text-lg font-semibold leading-6 ${darkMode ? 'text-white' : 'text-black'}`}>
          {t('recentlyViewed')}
        </h2>
        {loading && !(renderData && renderData?.length) ? (
          <NewLoading />
        ) : inUserCenter || inCartPage || inSearchPage ? (
          <div
            className={`grid gap-y-6`}
            style={{
              gridTemplateColumns: `repeat(${rowQuantity}, 180px)`,
              gridAutoFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            {(renderData || [])
              .slice(0, rowQuantity * (inUserCenter ? 2 : 1))
              .map((goodsItem) => {
                return {
                  ...goodsItem,
                  favorited:
                    pathname === '/favorites'
                      ? !!(collectData || []).find((x) => x.productId === goodsItem.productId)
                      : goodsItem.favorited,
                };
              })
              .map((x) => {
                return (
                  <div key={x.productId} className="w-[180px]">
                    <Goods
                      item={{
                        ...x,
                        favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                      }}
                      handleCollection={(value, goods) => {
                        if (handleCollection) {
                          handleCollection(value, goods);
                        }

                        const newData = renderData ? [...renderData] : [];
                        if (value) {
                          const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                          if (index > -1) {
                            newData[index].favorited = true;
                          }
                        } else {
                          const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                          if (index > -1) {
                            newData[index].favorited = false;
                          }
                        }
                        setRenderData(newData);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        ) : renderData?.length === rowQuantity ? (
          <div
            className={`grid gap-y-6`}
            style={{
              gridTemplateColumns: `repeat(${rowQuantity}, 180px)`,
              gridAutoFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            {(renderData || []).slice(0, rowQuantity).map((x) => {
              return (
                <div key={x.productId} className="w-[180px]">
                  <Goods
                    item={{
                      ...x,
                      favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                    }}
                    handleCollection={(value, goods) => {
                      if (handleCollection) {
                        handleCollection(value, goods);
                      }

                      const newData = renderData ? [...renderData] : [];
                      if (value) {
                        const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                        if (index > -1) {
                          newData[index].favorited = true;
                        }
                      } else {
                        const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                        if (index > -1) {
                          newData[index].favorited = false;
                        }
                      }
                      setRenderData(newData);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Carousel
            opts={{
              align: 'start',
            }}
            className="w-full"
          >
            <CarouselContent>
              {(renderData || []).map((browseHistoryItem, index) => (
                <CarouselItem
                  key={index}
                  className="basis-[180px] pl-0"
                  style={{ marginLeft: index === 0 ? 0 : paddingL }}
                >
                  <div
                    key={browseHistoryItem.productId}
                    className="flex w-[180px] flex-row items-center justify-center"
                  >
                    <div className="w-[180px]">
                      <Goods
                        noZoom={true}
                        item={{
                          ...browseHistoryItem,
                          favorited:
                            browseHistoryItem.productId === selectedGoods?.productId
                              ? selectedGoods?.favorited
                              : browseHistoryItem.favorited,
                        }}
                        handleCollection={(value, goods) => {
                          if (handleCollection) {
                            handleCollection(value, goods);
                          }

                          const newData = renderData ? [...renderData] : [];
                          if (value) {
                            const index = (newData || []).findIndex(
                              (goodItem) => goodItem.productId === goods.productId,
                            );
                            if (index > -1) {
                              newData[index].favorited = true;
                            }
                          } else {
                            const index = (newData || []).findIndex(
                              (goodItem) => goodItem.productId === goods.productId,
                            );
                            if (index > -1) {
                              newData[index].favorited = false;
                            }
                          }
                          setRenderData(newData);
                        }}
                      />
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        )}
      </div>
    </section>
  );

  return renderData?.length ? (
    renderData.length >= rowQuantity ? (
      <RenderData />
    ) : inUserCenter || inSearchPage || inCartPage ? (
      <RenderData />
    ) : (
      ''
    )
  ) : (
    ''
  );
};

export default RecentlyViewed;
